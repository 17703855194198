import React from "react";
import axios from "axios";
import Config from "../shared/Config";
const { appId, endpointHost }  = Config();
const endpointUrl = `https://${endpointHost}/app/${appId}/endpoint/storesettings`
const STORAGE_KEY = "store";
const headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json'
};

export function useStoreSettings(forceRefresh) {
  const { host } = window.location;
  const [store, setStore] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const config = { url: endpointUrl, headers};
    if (host) {
      config.url = `${endpointUrl}`; // ?host=${host}
    }
    if (forceRefresh) {
      sessionStorage.removeItem(STORAGE_KEY);
    }
    if (sessionStorage.getItem(STORAGE_KEY)) {
      setStore(JSON.parse(sessionStorage.getItem(STORAGE_KEY)));
      setLoading(false);
      return
    }
    axios(config)
        .then(response => {
          const { data } = response;
          if (data?.documents[0]) {
            const store = data.documents[0];
            console.log('useStoreSettings.js: fetch', { store });
            setStore(store);
            sessionStorage.setItem('store', JSON.stringify(store));
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch(err => {}); 
  }, [host, forceRefresh]);

  return {
    loading,
    store,
  };
}
