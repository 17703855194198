import { Navigate } from "react-router-dom";
import { useRealmApp } from "./RealmApp";

export function RequireAuth({ children }) {
    const { currentUser } = useRealmApp();
    if (currentUser?.isLoggedIn === true) {
      return children
    } else {
      sessionStorage.setItem('initPathname', window.location.pathname);
      return <Navigate to="/login" replace />;
    }
  }