import React, {useCallback} from "react";
import { useUploady } from "@rpldy/uploady";
import { Button } from "@mui/material";
import PhotoCamera from '@mui/icons-material/PhotoCamera';

export function UploadButton ({uploaded}) {
  const {showFileUpload} = useUploady();

  const onButtonClick = useCallback((e) => {
    console.log('showFileUpload dialog', e.target)
    // showFileUpload(uploadOptionsRef.current);
    showFileUpload()
}, [showFileUpload]);

  return <Button 
    style={{marginBottom:'1em'}} 
    variant="outlined" 
    onClick={onButtonClick}>
      <PhotoCamera />
      Upload {uploaded && "Another"}
    </Button>;
}
