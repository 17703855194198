import React from "react";
import { useWatch } from "./useWatch";
import { useCollection } from "./useCollection";
import { useRealmApp } from "../components/RealmApp";
import Config from "../shared/Config";
import {
  addValueAtIndex,
  replaceValueAtIndex,
  updateValueAtIndex,
  removeValueAtIndex,
  getTodoIndex,
} from "../utils";

const { dataSourceName } = Config();

export function useListings({store}) {
  // Set up a list of todos in state
  const realmApp = useRealmApp();
  const [listings, setListings] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  // Get a client object for the todo task collection
  const listingCollection = useCollection({
    cluster: dataSourceName,
    db: "ecommdb",
    collection: "listings",
  });

  // Fetch all todos on load and whenever our collection changes (e.g. if the current user changes)
  // const fields = {imageUrl: 1, title:1, price:1, _partition: 1, users:1 };
  React.useEffect(() => {
    listingCollection.aggregate([
      {
        $lookup:
          {
            from: "users",
            localField: "_partition",
            foreignField: "id",
            as: "seller"
          }
     },
     { "$match" : { "storeId" : store._id } }
   ]).then((res) => {
      console.log('useListings.js: fetch', {res})
      setListings(res.reverse());
      setLoading(false);
    });
  }, [listingCollection, store]);

  // Use a MongoDB change stream to reactively update state when operations succeed
  useWatch(listingCollection, {
    onInsert: (change) => {
      // console.log('useListings onInsert', change.fullDocument)
      setListings((oldTodos) => {
        if (loading) {
          return oldTodos;
        }
         const idx = getTodoIndex(oldTodos, change.fullDocument) ?? oldTodos.length;
        if (idx === oldTodos.length) {
          // insert at top of list
          return addValueAtIndex(oldTodos, 0, change.fullDocument);
        } else {
          return oldTodos;
        }
      });
    },
    onUpdate: (change) => {
      setListings((oldTodos) => {
        if (loading) {
          return oldTodos;
        }
        const idx = getTodoIndex(oldTodos, change.fullDocument);
        return updateValueAtIndex(oldTodos, idx, () => {
          return change.fullDocument;
        });
      });
    },
    onReplace: (change) => {
      setListings((oldTodos) => {
        if (loading) {
          return oldTodos;
        }
        const idx = getTodoIndex(oldTodos, change.fullDocument);
        return replaceValueAtIndex(oldTodos, idx, change.fullDocument);
      });
    },
    onDelete: (change) => {
      setListings((oldTodos) => {
        if (loading) {
          return oldTodos;
        }
        const idx = getTodoIndex(oldTodos, { _id: change.documentKey._id });
        if (idx >= 0) {
          return removeValueAtIndex(oldTodos, idx);
        } else {
          return oldTodos;
        }
      });
    },
  });

  // Given a draft todo, format it and then insert it
  const insertListing = async (draftTodo) => {
    if (draftTodo.imageUrl) {
      draftTodo._partition = realmApp.currentUser.id;
      try {
        await listingCollection.insertOne(draftTodo);
      } catch (err) {
        if (err.error.match(/^Duplicate key error/)) {
          console.warn(
            `The following error means that we tried to insert same _id multiple times. You may want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
          );
        }
        console.error(err);
      }
    }
  };

  const updateListing = async (listing) => {
    console.log({listing})
    try {
      await listingCollection.updateOne(
        {_id: listing._id},
        { $set: listing }
      );
    } catch (err) {
      if (err.error.match(/^Duplicate key error/)) {
        console.warn(
          `The following error means that we tried to insert a todo multiple times (i.e. an existing todo has the same _id). In this app we just catch the error and move on. In your app, you might want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
        );
      }
      console.error(err);
    }
  };

  // // Toggle whether or not a given todo is complete
  // const toggleTodo = async (todo) => {
  //   await listingCollection.updateOne(
  //     { _id: todo._id },
  //     { $set: { isComplete: !todo.isComplete } }
  //   );
  // };

  // // Delete a given todo
  const deleteListing = async (listing) => {
    await listingCollection.deleteOne({ _id: listing._id });
  };

  return {
    loading,
    listings,
    insertListing,
    updateListing,
    deleteListing
  };
}
