import { Container, Breadcrumbs, Link, Divider } from "@mui/material";
import { AccountBox, Home, LibraryAdd } from "@mui/icons-material";
import { useRealmApp } from "./RealmApp";
import { Link as RLink } from "react-router-dom";

export function MoreInfo() {
  return (
      <MoreInfoTemplateAndDocs />
  );
}

function MoreInfoItem({ children }) {
  return (
    <Container style={{ textAlign: "center", padding: "14px 0px 150px", marginTop: "auto" }}>
      {children}
    </Container>
  );
}

export function MoreInfoTemplateAndDocs() {
  const { currentUser } = useRealmApp();
  return (
    <MoreInfoItem>
      <Breadcrumbs aria-label="breadcrumb">
        <Link component={RLink} to={"/"}><Home /></Link>
        {currentUser && <Link component={RLink} to={"/profile"}><AccountBox /></Link>}
        {currentUser && <Link component={RLink} to={"/listings"}><LibraryAdd /></Link>}
      </Breadcrumbs>
      <Divider style={{margin:8}} light></Divider>
      <span>E-commerce store via <Link target="_blank" href="https://www.swag-shops.com/">Swag-Shops.com</Link></span>
    </MoreInfoItem>
  );
}