import React from "react";
import axios from "axios";
import Config from "../shared/Config";
const { appId, endpointHost } = Config();
const endpointUrl = `https://${endpointHost}/app/${appId}/endpoint/listings`
const headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json'
};

function useItems({ category, limit, store }) {
  const [items, setItems] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  // headers['Authorization'] = 'abc'

  React.useEffect(() => {
    const config = { url: endpointUrl, headers };
    if (category) {
      config.url = `${endpointUrl}?category=${category}`
    }
    if (limit) {
      config.url = `${endpointUrl}?limit=${limit}`
    }
    if (limit && category) { // TODO: add Url parser
      config.url = `${endpointUrl}?category=${category}&limit=${limit}`
    }

    config.url = `${config.url}&storeId=${store._id}`;

    setLoading(true);
    axios(config)
      .then(response => {
        const { data } = response;
        console.log(`useItems.js: fetch ${category}, ${limit}`, { data });
        setItems(data.documents)
        setLoading(false)
      })
      .catch(err => { });
  }, [category, limit, store]);

  return { loading, items };
}


export { useItems }