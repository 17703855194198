import React from "react";
import { Container, Divider, LinearProgress } from "@mui/material";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { MoreInfo } from "../components/MoreInfo";
import { HomePage } from "./HomePage";
import { ListingsPage } from "./ListingsPage";
import { RealmAppProvider, useRealmApp } from "../components/RealmApp";
import { ThemeProvider } from "../components/Theme";
import Config from "../shared/Config";
import "./App.css";
import AuthRedirect from "./AuthRedirect";
import { ItemCardDetail } from "./ItemCardDetail";
import { Login } from "./Login";
import { ProfilePage } from "./ProfilePage";
import { RedirectPage } from "./RedirectPage";
import { CategoryPage } from "./CategoryPage";
import { StoreEditPage } from "./StoreEditPage";
import { AppTopBar } from "../components/AppTopBar";
import { useStoreSettings } from "../hooks/useStoreSettings";
import { RequireAuth } from "../components/RequireAuth";
import { LoginDialog } from "../components/LoginDialog";
import { ResetPasswordPage } from "./ResetPasswordPage";
//import { ItemsPage } from "./ItemsPage";

const { appId } = Config();

const initialOptions = {
  "client-id": "test",
  currency: "USD",
  intent: "capture",
  components: "buttons,funding-eligibility",
  "enable-funding": "venmo",
  // "data-client-token": "abc123xyz==",
};

export default function AppWithRealm() {
  return (
    <ThemeProvider>
      <RealmAppProvider appId={appId}>
        <PayPalScriptProvider options={initialOptions}>
          <App />
        </PayPalScriptProvider>
      </RealmAppProvider>
    </ThemeProvider>
  );
}

function App() {
  const { currentUser } = useRealmApp();
  const { store, loading } = useStoreSettings(currentUser); // use true to force refresh
  const [showLogin, setShowLogin] = React.useState(false);

  if (store.name) {
    console.log('App.js',{appId}, {currentUser}, {store});
  }

  return (
    <div className="App">
      <Router>
        <AppTopBar store={store} currentUser={currentUser} setShowLogin={setShowLogin}  />
        {loading ? <LinearProgress />:
        <Container id="app-container" disableGutters={true}>
          <LoginDialog open={showLogin} setShowLogin={setShowLogin} />
          <Routes>
            <Route path="/" element={<HomePage store={store} />} />
            <Route path="/redirect" element={<RedirectPage />} />
            {/* <Route path="/items" element={<ItemsPage />} /> */}
            <Route path="/items/:itemId" element={<ItemCardDetail setShowLogin={setShowLogin} />} />
            <Route path="/login" element={<Login store={store} />} />
            <Route path="/auth/callback" element={<AuthRedirect />}/>
            <Route path="/resetPassword" element={<ResetPasswordPage />}/>
            <Route path="/category/:category" element={<CategoryPage store={store} />}/>
            <Route path="/listings" element={<RequireAuth><ListingsPage store={store} currentUser={currentUser}  /></RequireAuth>} />
            <Route path="/profile" element={<RequireAuth><ProfilePage currentUser={currentUser}  /></RequireAuth>} />
            <Route path="/shops" element={<RequireAuth><StoreEditPage currentUser={currentUser} /></RequireAuth>}/>
          </Routes>
          {/* Footer */}
          <Divider style={{marginTop:9}} light></Divider>
          <MoreInfo />
        </Container>}
      </Router>
    </div>
  );
}
