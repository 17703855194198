import axios from "axios";
import Config from "./Config";
const { appId, endpointHost }  = Config();
const endpointUrl = `https://${endpointHost}/app/${appId}/endpoint/search`
const headers = { 
  'Content-Type': 'application/json',
  'Accept': 'application/json'
};

export async function SearchItems(text) {
    const config = { url: endpointUrl, headers};
    if (text) {
      config.url = `${endpointUrl}?text=${text}`
    }
    return axios(config)
        .then(response => {
          const { data } = response;
          console.log('Search.js: fetch', { data });
          return data.documents;
        })
        .catch(err => {
            console.error('search error:', { err });
        });
}
