import { Button, Card, Container, TextField, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { useRealmApp } from "../components/RealmApp";

export function ResetPasswordPage() {
    const realmApp = useRealmApp();
    const [error, setError] = React.useState('');
    const newPassword = React.useRef();
    const [resetDone, setResetDone] = React.useState(false);
    const { token, tokenId } = useParams();

    const buttonHandler = React.useCallback(async (event) => {
          event.preventDefault();
          const data = { newPassword: newPassword.current?.value };
          // console.log(data);
          try {
                await realmApp.emailPasswordAuth.resetPassword({
                    password: data.newPassword,
                    token,
                    tokenId,
                });
                setError('')
                setResetDone(true)
            } catch (ex) {
                console.log('err:', ex.error);
                setError(ex.error);
            }
        },
        [token, tokenId, realmApp.emailPasswordAuth]
      );

    return (
        <Container maxWidth="sm" className="main-container">
            <Card className="auth-card" variant="outlined">
                <Typography style={{ marginBottom: 5 }} variant="h6">Reset password</Typography>
                <TextField
                    id="input-reset-password"
                    name="password"
                    label="New Password"
                    variant="outlined"
                    inputRef={newPassword}
                    error={Boolean(error)}
                    helperText={error ?? ""}
                />
                <Button variant="contained" color="primary" onClick={buttonHandler}>
                    Reset password
                </Button>
            {resetDone ? <div>Reset Complete</div> : ''}
            </Card>
        </Container>
    )

}