import { AccountBox } from "@mui/icons-material";
import { AppBar, Button, Toolbar, Typography } from "@mui/material";
import { AppName } from "./AppName";
import { Link } from "react-router-dom";

export function AppTopBar({ currentUser, setShowLogin, store }) {
    return (
        <AppBar position="static">
        <Toolbar>
          <AppName store={store} />
          {currentUser ? (
            <Button
              component={Link}
              size="small"
              variant="contained"
              color="secondary"
              to={"/profile"}
              >
                <AccountBox size="small" /> {currentUser.customData?.fullname?.slice(0,10)}
            </Button>
          ) : 
          <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={async () => {
                setShowLogin(true);
              }}>
              <Typography variant="button">Log In</Typography>
            </Button>}
        </Toolbar>
      </AppBar>
    )
}