import React from "react";
import * as Realm from "realm-web";
import { useCollection } from "./useCollection";
import { useRealmApp } from "../components/RealmApp";
import Config from "../shared/Config";
import { addItemToArray } from "./util-hooks";

const { dataSourceName } = Config();

export function useStores() {
  const realmApp = useRealmApp();
  const [stores, setStores] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const collection = useCollection({
    cluster: dataSourceName,
    db: "ecommdb",
    collection: "stores",
  });

  React.useEffect(() => {
    collection.aggregate([]).then((res) => {
      console.log('useStores.js: fetch:mongo', { res })
      setStores(res);
      setLoading(false);
    });
  }, [collection]);

  const createNewStore = async () => {
    const cnt = stores?.length + 1
    const id = new Realm.BSON.ObjectID()
    const store = {
      _id: id,
      admins: Array(realmApp.currentUser.id),
      name: `New store #${cnt}`,
      isConsignment: false,
      domains: Array(`${id.toString().substring(0, 6)}.swag-shops.com`),
      categories: [{ name: "Other", slug: "other", cover: true }],
      options: [{ category: 'Test' }, { category: 'Other' }, { size: 'S' }, { size: 'M' }, { size: 'L' }],
      updated_ts: new Date().getTime()
    };
    try {
      await collection.insertOne(store);
    } catch (err) {
      if (err.error.match(/^Duplicate key error/)) {
        console.warn('Error: tried to insert same _id multiple times. You may want to debounce the save input or implement an additional loading state to avoid sending duplicate request');
      }
      console.error(err);
    }

    setStores(addItemToArray(store, stores));
  };

  const updateStore = async (store) => {
    console.log({ store })
    try {
      await collection.updateOne(
        { _id: store._id },
        { $set: store }
      );
    } catch (err) {
      if (err.error.match(/^Duplicate key error/)) {
        console.warn('Error: tried to insert same _id multiple times. You may want to debounce the save input or implement an additional loading state to avoid sending duplicate request');
      }
      console.error(err);
    }
  };

  // Delete a store
  const deleteStore = async (store) => {
    await collection.deleteOne({ _id: store._id });
    const storesClone = Array.from(stores.filter(s => s._id !== store._id));
    setStores(storesClone);
  };

  return {
    loading,
    stores,
    createNewStore,
    updateStore,
    deleteStore
  };
}
