import React from "react";
import { makeStyles } from '@mui/styles';
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  // CircularProgress,
  Link
} from "@mui/material";

const useStyles = makeStyles({
  root: {maxWidth: 640, marginTop:12},
  media: { maxHeight: 360 },
});

export function ItemCard({ item, itemActions }) {
  const classes = useStyles();
  
  return (
    <Card className={classes.root}>
       <Link color="primary" href={`/items/${item._id}`} component="a">
        <CardMedia
          component="img"
          className={classes.media}
          image={item.imageUrl.split('image/upload/').join('image/upload/c_pad,b_lightgrey,w_600,h_1060/')}
          title={"Item_" + item._id} // w_600,h_800,c_fill
        />
         <CardContent>
            {item.title} {"$"+ item.price}
          <Typography variant="body2" color="textSecondary" component="p">
            {item.category}{item.size && ", Size:" + item.size}
          </Typography>
        </CardContent>
        </Link>
    </Card>
  );
}
