import { Box, Card, LinearProgress, List, TextField } from "@mui/material";
import React from "react";
import { SearchItems } from "../shared/Search";
import { ItemCard } from "./ItemCard";

export function SearchBar() {
    const [searchItems, setSearchItems] = React.useState([]);
    const [loadingSearch, setSearchLoading] = React.useState(false);
    const [searchActive, setSearchActive] = React.useState(false);
  
    const handleInputChange = async (e) => {
        const len = e.target.value.length
        if (e.target.value === "") {
          console.log('clear input', e.target.value)
          setSearchActive(false);
          setSearchItems([]);
        }
        if (len > 2) { // && !(len % 2)
          setSearchActive(true);
          console.log('search:', e.target.value)
          setSearchLoading(true);
          const data = await SearchItems(e.target.value)
          console.log({data})
          setSearchItems(data);
          setSearchLoading(false);
        }
    
      };

      return (
      <Card>
        <Box component="form" noValidate autoComplete="off" style={{marginTop:6}}>
          <TextField style={{display:'flex'}} 
            id="outlined-search"
            label="Search items"
            type="search" 
            variant="outlined"
            onChange={handleInputChange}
          />
        </Box>
        {(searchItems?.length > 0) && 
        <List style={{ width: "100%" }}>
          {searchItems.map((item) => (
              <ItemCard
                key={String(item._id)}
                item={item}
              />
          ))}
        </List>
        }
        {searchActive && (searchItems?.length === 0) && <div>No search results</div>}
        {loadingSearch && <LinearProgress />}
      </Card>
      )

}