import React, {useCallback, useMemo} from "react";
import {
  CircularProgress,
  TextField,
  Button,
  Card,
  CardActions,
  CardMedia,
  CardContent,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import { UploadButton } from "./UploadButton";
import {Clear as ClearIcon} from "@mui/icons-material";
import Uploady, { UPLOADER_EVENTS } from "@rpldy/uploady";
import { useStoreSettings } from "../hooks/useStoreSettings";
const CLOUD_NAME = "dg6z5xnnv";
const UPLOAD_PRESET = "unsigned_ninja";

const useStyles = makeStyles({
  root: { maxWidth: 520 },
  placeholder : { height:200 },
  media: { height: 0, paddingTop: '120%'}
});

export function DraftItemCard({ item, listingActions, draftActions, consignors, categories, sizes }) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [uploaded, setUploaded] = React.useState(false);
  const [formComplete, setFormComplete] = React.useState(false);
  const { store } = useStoreSettings();

  const resFormatter = useCallback((res, status, headers) => {
    const response = JSON.parse(res)
    console.log("### URL:", response.secure_url, status);
    draftActions.setDraftImageUrl(item, response.secure_url);
    return `${status} - OK!`;
  }, [draftActions, item]);

  const handleTextFieldChange = (e) => {
    console.log('target:', e.target, {item});
    draftActions.setDraftTextField(item, e.target);
  };

  const listeners = useMemo(() => ({
    // [UPLOADER_EVENTS.BATCH_START]: (batch) => { console.log(`Batch Start - ${batch.id} - item count = ${batch.items.length}`);},
    // [UPLOADER_EVENTS.BATCH_FINISH]: (batch) => { console.log(`Batch Finish - ${batch.id} - item count = ${batch.items.length}`); },
    [UPLOADER_EVENTS.ITEM_START]: (item) => {
      setLoading(true);
      console.log(`Item Start - ${item.id} : ${item.file.name}`);
    },
    [UPLOADER_EVENTS.ITEM_FINISH]: (item) => {
      setLoading(false);
      setUploaded(true);
      console.log(`Item Finish - ${item.id} : ${item.file.name}`);
    },
  }), []);
  return (
    <Card className={classes.root}>
      {uploaded ? <CardMedia
        className={classes.media}
        image={item.imageUrl.split('image/upload/').join('image/upload/c_pad,b_lightgrey,w_800,h_1060/')}
        title="Product"
      /> :
       <CardMedia
        className={classes.placeholder}
        image={'https://via.placeholder.com/800x600.png/eee/000?text=Upload+Product+Image'}
        title="Draft Product"
      />}
      {item.imageUrl2 &&
      <CardMedia
        className={classes.media}
        image={item.imageUrl2.split('image/upload/').join('image/upload/c_pad,b_lightgrey,w_800,h_1060/')}
        title="Product"
      />
      }
      <CardContent>
        <Uploady
          listeners={listeners}
          multiple={false}
          grouped
          maxGroupSize={2}
          method="PUT"
          formatServerResponse={resFormatter}
          destination={{
              url: `https://api.cloudinary.com/v1_1/${CLOUD_NAME}/upload`,
              params: {
                  upload_preset: UPLOAD_PRESET,
              }
          }}>
          <UploadButton uploaded={uploaded} style={{marginBottom:'11'}} />
          {loading && <CircularProgress />}
        </Uploady>
        <span className="auth-form">
          <TextField
            id="input-title"
            name="title"
            label="Title"
            variant="outlined"
            style={{ width: "100%" }}
            onChange={(e) => {
              if (e.target.value?.length > 3) {
                setFormComplete(true)
              } else {
                setFormComplete(false)
              } 
              draftActions.setDraftTextField(item, e.target);
            }}
          />
          <TextField
            id="input-price"
            name="price"
            label="Price"
            variant="outlined"
            style={{ width: "100%" }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            onChange={(e) => {
              draftActions.setDraftPrice(item, e.target.value);
            }}/>
           <FormControl className={classes.formControl}>
            <InputLabel id="category-label">Category</InputLabel>
            <Select
              labelId="category-label"
              id="select-category"
              label="Category"
              name="category"
              defaultValue=""
              onChange={handleTextFieldChange}>
              {categories.map((cat) => (
                <MenuItem key={String(cat.slug)} value={cat.slug}>{cat.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="size-label">Size</InputLabel>
            <Select
              id="select-size"
              labelId="size-label"
              name="size"
              label="Size"
              defaultValue=""
              onChange={handleTextFieldChange}>
              {sizes.map((item) => (
                <MenuItem key={String(item.key)} value={item.key}>{item.key}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="consignor-label">Consignor</InputLabel>
            <Select
              id="select-consignor"
              labelId="consignor-label"
              name="consignor"
              defaultValue=""
              label="Consignor"
              onChange={handleTextFieldChange}>
              {consignors.map((i) => (
                <MenuItem key={String(i._id)} value={i.venmo}>{i.name} ({i.venmo})</MenuItem>
              ))}
            </Select>
          </FormControl>
        </span>
      </CardContent>
      <CardActions>
      {loading && <CircularProgress />}
      {(uploaded && formComplete) &&
        <Button
          color="primary"
          variant="outlined"
          size="medium"
          onClick={async () => {
            item.storeId = store._id;
            console.log({item});
            await listingActions.insertListing(item);
            draftActions.deleteDraftItem(item);
          }}> Save
          </Button>
        }
        <Button
          variant="outlined"
          edge="end"
          size="medium"
          onClick={() => {
            draftActions.deleteDraftItem(item);
          }}
        > Cancel
          <ClearIcon />
        </Button>
      </CardActions>
    </Card>
  );
}
