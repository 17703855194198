import React from "react";
import { Container, LinearProgress } from "@mui/material";
import { useItems } from "../hooks/useItems";
import { useShowLoader } from "../hooks/util-hooks";
import { SingleImageList } from "../components/SingleImageList";
import { Link } from "react-router-dom";
import { SearchBar } from "../components/SearchBar";

function mapCats(cat) {
  if (cat.home) {
    return cat;
  }
}

export function HomePage({ store }) {  
  const coverCategories = store.categories.map(mapCats).filter(a => a);
  // console.log({store});
  const { items, loading } = useItems({ limit: 40, store });
  const coverItems = {
    cat0: items.filter(i => i.category === coverCategories[0]?.slug),
    cat1: items.filter(i => i.category === coverCategories[1]?.slug),
    cat2: items.filter(i => i.category === coverCategories[2]?.slug),
  };
  const showLoader = useShowLoader(loading, 200);

  return (
    <Container style={{ marginTop: 15 }} maxWidth="sm" className="main-container">
      {loading ? (
        showLoader ? (
          <LinearProgress />
        ) : <div />
      ) : (
        <div>
          <SearchBar />
          <h4><Link xs={{ color: '#00000' }} to={"/category/New"}>New</Link></h4>
          <SingleImageList items={items.slice(0, 7)} />
          {coverCategories.map((cat, idx) => (
            <span key={idx}>
              <h4><Link to={`/category/${cat.slug}`}>{cat.name}</Link></h4>
              <SingleImageList items={coverItems[`cat${idx}`]} />
            </span>
          ))}
        </div>)}
    </Container>
  );
}
