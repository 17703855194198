import React from "react";
import {
  Button,
  Typography,
  TextField,
  FormControl,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
  Divider
} from "@mui/material";
import { Publish } from "@mui/icons-material";
import { StoreDomainEdit } from "./StoreDomainEdit";


export function StoreEditForm({ item, actions, currentUser }) {
  const [isFormDirty, setFormDirty] = React.useState(false);
  const admins = ['bradoyler@gmail.com']
  const isAdmin = admins.includes(currentUser.profile.email);

  function disableSave() {
    // TODO: enable for store admins
    if (isAdmin && isFormDirty) {
      return false;
    }
    return true;
  }

  const noErrors = {
    name: null,
    isConsignment: null,
    other: null,
  };

  const [error] = React.useState(noErrors);

  const handleInputChange = (event) => {
    setFormDirty(true);
  };

  return (
    <span id="storeditform">
      <form className="auth-form"
        onSubmit={async (e) => {
          e.preventDefault();
          const formData = new FormData(e.target);
          const { name, isConsignment, categories, options } = Object.fromEntries(formData.entries());
          item.name = name;
          item.isConsignment = Boolean(isConsignment === 'true');
          if (categories) {
            item.categories = JSON.parse(categories);
          }
          if (options) {
            item.options = JSON.parse(options);
          }
          item.updated_ts = new Date().getTime();
          console.log('update:', { item }, { isConsignment });
          await actions.updateStore(item);
          setFormDirty(false);
        }}
      >
        <TextField
          id="input-name"
          defaultValue={item.name}
          name="name"
          label="Name"
          variant="outlined"
          error={Boolean(error.name)}
          onChange={handleInputChange}
          helperText={error.name ?? ""}
        />
        <FormControl>
          <InputLabel id="isConsignment-label">Enable Consignment?</InputLabel>
          <Select
            id="select-isConsignment"
            labelId="isConsignment-label"
            name="isConsignment"
            label="Enable Consignment?"
            defaultValue={item.isConsignment}
            onChange={handleInputChange}>
            <MenuItem key={String(true)} value={true}>True</MenuItem>
            <MenuItem key={String(false)} value={false}>False</MenuItem>
          </Select>
        </FormControl>
        <TextField
          id="input-categories"
          multiline
          rows={4}
          name="categories"
          defaultValue={JSON.stringify(item.categories)}
          label="Item Categories"
          variant="outlined"
          onChange={handleInputChange}
          InputProps={{
            startAdornment: <InputAdornment position="start"></InputAdornment>,
          }}
        />
        <TextField
          id="input-options"
          multiline
          rows={4}
          name="options"
          defaultValue={JSON.stringify(item.options)}
          label="Item Options"
          variant="outlined"
          onChange={handleInputChange}
          InputProps={{
            startAdornment: <InputAdornment position="start"></InputAdornment>,
          }}
        />
        <span>
          <Button
            disabled={disableSave()}
            type="submit" variant="contained" color="primary">
            Save <Publish />
          </Button>
        </span>
      </form>
      <Divider style={{ marginTop: 12 }} />

      {isAdmin ? <StoreDomainEdit item={item} actions={actions} />:''}
      <div>
        <Typography component="p">Status: {item.status}</Typography>
      </div>
    </span>
  );
}
