import React from "react";
import {
  Button,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment
} from "@mui/material";
import { Clear, Publish } from "@mui/icons-material";
import { useStoreSettings } from "../hooks/useStoreSettings";


export function EditItemForm({ item, listingActions, isEditMode, setEditMode, categories, sizes, consignors }) {
  const [isFormDirty, setFormDirty] = React.useState(false);
  const { store } = useStoreSettings();
  
  const noErrors = {
    email: null,
    password: null,
    other: null,
  };

  const [error] = React.useState(noErrors);

  const handleInputChange = (event) => {
    setFormDirty(true);
  };

  return (
      <>
        {isEditMode && 
          <form
            className="auth-form"
            onSubmit={async(e) => {
              e.preventDefault();
              const formData = new FormData(e.target);
              const {title, price, category, size, consignor} = Object.fromEntries(formData.entries());
              item.title = title;
              item.consignor = consignor;
              item.price = Number(price);
              item.category = category;
              item.size = size;
              item.storeId = store._id;
              item.updated_ts = new Date().getTime();
              await listingActions.updateListing(item);
              setEditMode(false);
            }}
          >
          <TextField
            id="input-title"
            defaultValue={item.title}
            name="title"
            label="Title"
            variant="outlined"
            error={Boolean(error.title)}
            onChange={handleInputChange}
            helperText={error.title ?? ""}
          />
          <TextField
            id="input-price"
            name="price"
            defaultValue={item.price}
            label="Price"
            variant="outlined"
            error={Boolean(error.price)}
            onChange={handleInputChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            helperText={error.price ?? ""}
          />
          {categories?.length>0 && 
          <FormControl>
            <InputLabel id="category-label">Category</InputLabel>
            <Select
              id="select-category"
              labelId="category-label"
              name="category"
              label="Category"
              defaultValue={item.category}
              onChange={handleInputChange}>
              {categories.map((cat) => (
                <MenuItem key={String(cat.slug)} value={cat.slug}>{cat.name}</MenuItem>
              ))}
            </Select>
          </FormControl>}
          {sizes?.length > 0 &&
          <FormControl>
            <InputLabel id="size-label">Size</InputLabel>
            <Select
              id="select-size"
              labelId="size-label"
              name="size"
              label="Size"
              defaultValue={item.size}
              onChange={handleInputChange}>
              {sizes.map((item) => (
                <MenuItem key={String(item.key)} value={item.key}>{item.key}</MenuItem>
              ))}
            </Select>
          </FormControl>
          }
          <FormControl>
            <InputLabel id="consignor-label">Consignor</InputLabel>
            <Select
              id="select-consignor"
              labelId="consignor-label"
              name="consignor"
              defaultValue={item.consignor}
              label="Consignor"
              onChange={handleInputChange}>
              {consignors.map((i) => (
                <MenuItem key={String(i._id)} value={i.venmo}>{i.name} ({i.venmo})</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <Typography component="p">Status: {item.status}</Typography>
            <Typography component="p">updated: {new Date(item.updated_ts).toString()}</Typography>
          </FormControl>
          <span>
            <Button disabled={!isFormDirty} style={{marginRight:20}} type="submit" variant="contained" color="primary">
              Save <Publish />
            </Button>
            <Button variant="contained" color="secondary"
              onClick={() => {
                setEditMode(false)
              }}
            >Cancel <Clear/></Button> 
          </span>
        </form>}
      </>
  );
}
