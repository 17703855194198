import * as Realm from "realm-web";
import { Button } from "@mui/material";
import { Facebook } from "@mui/icons-material";


export function FBLoginButton({realmApp, setShowLogin}) {
    // const { origin, hostname } = window.location; 
    // const enabledHostnames = ['localhost', 'www.swag-shops.com', 'dev.swag-shops.com'];
    const enableFBogin = true; // TODO: get from storeSettings; enabledHostnames.includes(hostname);
    
    return (
        <Button style={{backgroundColor: '#1877F2', margin:'auto'}} disabled={!enableFBogin} type="submit" variant="contained" size="medium" onClick={(e) => {
            // The redirect URI should be on the same domain as this app and
            // specified in the auth provider configuration.
            const redirectUri = `${origin}/auth/callback`; // "http://localhost:3000/auth/callback";
            console.log({redirectUri})
            const credentials = Realm.Credentials.facebook(redirectUri);
            // Calling logIn() opens a Facebook authentication screen in a new window.
            realmApp.logIn(credentials).then((user) => {
              // The logIn() promise will not resolve until you call `handleAuthRedirect()`
              // from the new window after the user has successfully authenticated.
              console.log(`Logged in with id: {user.id}`, { user });
              setShowLogin(false); // close Dialog
            });
            // logs the user in.
            // Realm.handleAuthRedirect();
          }}> <Facebook style={{marginRight:5}} /> Facebook Login</Button>
    )    
}