import { ArrowBack } from "@mui/icons-material";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import { EmailLoginForm } from "./EmailLoginForm";
import { FBLoginButton } from "./FBLoginButton";
import { useRealmApp } from "./RealmApp";

export function LoginDialog({ open, setShowLogin }) {
    const realmApp = useRealmApp();
    const [emailLogin, setEmailLogin] = React.useState(false);
    const [isSignup, setIsSignup] = React.useState(false);
  
    const handleClose = (value) => {
        setShowLogin(false);
    };
    
    return (
        <Dialog onClose={handleClose} open={open} fullWidth={false}>
            <DialogTitle>{isSignup ? "Sign Up" : "Log In"}</DialogTitle>
            <DialogContent>
                {!emailLogin ? 
                <div style={{textAlign:'center'}}>
                    <FBLoginButton realmApp={realmApp} setShowLogin={setShowLogin} />
                    <h3>OR</h3>
                    <Button style={{margin:'auto', textAlign:'center'}} variant="outlined" onClick={()=> setEmailLogin(true)}>Login with email</Button>
                </div> :
                <span>
                    <EmailLoginForm realmApp={realmApp} isSignup={isSignup} setIsSignup={setIsSignup} setShowLogin={setShowLogin} />
                    <Button onClick={()=> {
                        setEmailLogin(false);
                        setIsSignup(false);
                    }}><ArrowBack /></Button>
                </span>
                }
            </DialogContent>
        </Dialog>
    )
}