import React from "react";
import {
  Container,
  Button,
  Typography,
  List,
  LinearProgress,
  Card
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Add as AddIcon } from "@mui/icons-material";
import { useShowLoader } from "../hooks/util-hooks";
import { useStores } from "../hooks/useStores";
import { StoreEditForm } from "../components/StoreEditForm";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: 1, // theme.spacing(1),
  },
}));

export function StoreEditPage({currentUser}) {
  // console.log(currentUser.profile.email, 'email');
  const classes = useStyles();
  const { loading, stores, ...actions } = useStores();
  const showLoader = useShowLoader(loading, 200);
  return (
    <Container className="main-container" disableGutters={true} maxWidth="sm">
      {loading ? (
        showLoader ? (
          <LinearProgress />
        ) : <div />
      ) : (
        <div>
          <div>
            <Button
              disabled={(!currentUser.profile.email === 'bradoyler@gmail.com')}
              className={classes.button}
              size="small"
              variant="contained"
              color="primary"
              endIcon={<AddIcon />}
              onClick={(e) => {
                const newStore = actions.createNewStore();
                stores.push(newStore);
              }}
            >Create Shop</Button>
          </div>
          {(stores.length === 0) && 
            <Typography variant="h5">Create your first Shop</Typography>
          }
          <List style={{ width: "100%", marginTop:3 }}>
            {stores.map((store) => (
              <span key={String(store.name)}>
                <Typography variant="h6">&nbsp; {store.name}</Typography>
                <Card sx={{paddingTop:2, paddingLeft:1, paddingRight:1, paddingBottom:3, marginBottom:3}}>
                  <StoreEditForm item={store} actions={actions} isEditMode={true} currentUser={currentUser} />
                </Card>
              </span>
            ))}
          </List>
        </div>
      )}
    </Container>
  );
}
