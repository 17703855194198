import React from "react";
import {
  Container,
  LinearProgress,
  ImageList,
  ImageListItem,
  ImageListItemBar
} from "@mui/material";
import { useItems } from "../hooks/useItems";
import { useShowLoader } from "../hooks/util-hooks";
import { Link, useParams } from "react-router-dom";

export function CategoryPage({store}) {
  const { category } = useParams();
  let catSearch = category
  if (category === "New") {
    catSearch = "$ALL";
  }
  const { items, loading } = useItems({category:catSearch, limit: 40, store});
  const showLoader = useShowLoader(loading, 200);

  return (
    <Container style={{marginTop:15}} maxWidth="sm" className="main-container">
      {loading ? (
        showLoader ? (
          <LinearProgress />
        ) : <div />
      ) : (
      <div>
        <h3>{category}</h3>
        <ImageList>
          {items.map((item) => (
            <ImageListItem key={item._id}>
              <Link to={"/items/"+ item._id}>
                <img style={{width: '100%'}} src={item.imageUrl ? item.imageUrl.split('image/upload/').join('image/upload/c_pad,b_lightgrey,w_600,h_600/'):''} alt={item.title} />
                <ImageListItemBar title={item.title} />
              </Link>
            </ImageListItem>
          ))}
        </ImageList>
      </div>)}
    </Container>
  );
}
