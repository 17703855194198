import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material";
// import { colors } from "../colors";

const themeConfig = {
  palette: {
    primary: { main: '#000000' },
    secondary: { main: '#EEE' },
  },
}

const theme = createTheme(themeConfig);

export function ThemeProvider({ children }) {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
