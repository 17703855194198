import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Link } from "react-router-dom";

export function StoresTable({stores}) {
    
    return (
        <Table sx={{ minWidth: 380 }} size="small" aria-label="stores table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>PaymentMethod</TableCell>
            <TableCell>Updated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {stores.map((s) => (
          <TableRow key={s._id} 
             sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row"><Link to={"/shops"}>{s.name}</Link></TableCell>
            <TableCell>{s.paymentMethod || '--'}</TableCell>
            <TableCell>{new Date(s.updated_ts).toDateString() || '--'}</TableCell>
          </TableRow>
        ))}
        </TableBody>
      </Table>
    )
}