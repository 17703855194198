import React from "react";
import * as Realm from "realm-web";

export function useDraftItems() {
  const [drafts, setDrafts] = React.useState([]);

  const createDraftItem = (store) => {
    const draftItem = {
      _id: new Realm.BSON.ObjectID(),
      created_ts: new Date().getTime(),
      updated_ts: new Date().getTime(),
      storeId: store._id,
      price: 0,
      status: "Active",
      title: "",
      summary: "",
      imageUrl: "",
      imageUrl2: "",
      imageUrl3: "",
      imageUrl4: "",
      imageUrl5: "",
      category: "",
      consignor: ""
    };
    setDrafts((d) => [...d, draftItem]);
  };

  const setDraftImageUrl = (draft, imageUrl) => {
    let fieldName = 'imageUrl'
    if (draft.imageUrl) {
      fieldName = 'imageUrl2';
    }
    if (draft.imageUrl2) {
      fieldName = 'imageUrl3';
    }
    if (draft.imageUrl3) {
      fieldName = 'imageUrl4';
    }
    console.log('setDraftImageUrl', {[`${fieldName}`]: imageUrl})
    setDrafts((oldDrafts) => {
      const idx = oldDrafts.findIndex((d) => d._id === draft._id);
      return [
        ...oldDrafts.slice(0, idx),
        { ...oldDrafts[idx], [`${fieldName}`]: imageUrl },
        ...oldDrafts.slice(idx + 1),
      ];
    });
  };

  const setDraftTextField = (draft, field) => {
    setDrafts((oldDrafts) => {
      const idx = oldDrafts.findIndex((d) => d._id === draft._id);
       return [
        ...oldDrafts.slice(0, idx),
        { ...oldDrafts[idx], [field.name]: field.value },
        ...oldDrafts.slice(idx + 1),
      ];
    });

  };

  const setDraftPrice = (draft, priceVal) => {
    setDrafts((oldDrafts) => {
      const price = parseFloat(priceVal);
      console.log(price, priceVal)
      const idx = oldDrafts.findIndex((d) => d._id === draft._id);
      return [
        ...oldDrafts.slice(0, idx),
        { ...oldDrafts[idx], price:Number(price) },
        ...oldDrafts.slice(idx + 1),
      ];
    });
  };

  const deleteDraftItem = (draft) => {
    setDrafts((oldDrafts) => {
      const idx = oldDrafts.findIndex((d) => d._id === draft._id);
      return [...oldDrafts.slice(0, idx), ...oldDrafts.slice(idx + 1)];
    });
  };

  return {
    draftItems: drafts,
    setDraftTextField,
    createDraftItem,
    setDraftPrice,
    setDraftImageUrl,
    deleteDraftItem,
  };
}
