import React from "react";
import { LoginDialog } from "../components/LoginDialog";
import { HomePage } from "./HomePage";

export function Login({store}) {
  const [showLogin, setShowLogin] = React.useState(true);
  return (
    <span>
      <LoginDialog open={showLogin} setShowLogin={setShowLogin} />
      <HomePage store={store} />
    </span>
  );
}
