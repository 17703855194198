import React from "react";
import {
  Container,
  Button,
  Typography,
  List,
  LinearProgress
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { useListings } from "../hooks/useListings";
import { DraftItemCard } from "../components/DraftItemCard";
import { ListingCard } from "../components/ListingCard";
import { useDraftItems } from "../hooks/useDraftItems";
import { useShowLoader } from "../hooks/util-hooks";
import { useConsignors } from "../hooks/useConsignors";
import { filterOptions } from "../utils";

export function ListingsPage({store}) {
  const { loading, listings, ...listingActions } = useListings({store});
  const { items: consignors } = useConsignors();
  const { draftItems, ...draftActions } = useDraftItems();
  const showLoader = useShowLoader(loading, 200);

  const sizes = filterOptions(store.options, 'size');
  const { categories } = store;
  return (
    <Container disableGutters={true} className="main-container" maxWidth="sm">
      {loading ? (
        showLoader ? (
          <LinearProgress />
        ) : <div />
      ) : (
        <div>
          <div>
          <Button
            style={{margin:1}}
            size="small"
            variant="contained"
            color="primary"
            endIcon={<AddIcon />}
            onClick={() => draftActions.createDraftItem(store)}
          >Add</Button>
          <Typography component="span" variant="subtitle2">
            {`${listings.length} item${
              listings.length === 1 ? "" : "s"
            }`} active
          </Typography>
          </div>
                    
          <List style={{ width: "100%" }}>
          {draftItems.map((draft) => (
              <DraftItemCard
                sizes={sizes}
                categories={categories}
                key={String(draft._id)}
                item={draft}
                consignors={consignors}
                listingActions={listingActions}
                draftActions={draftActions}
              />
            ))}
            {listings.map((item) => (
                <ListingCard
                  sizes={sizes}
                  categories={categories}
                  consignors={consignors}
                  key={String(item._id)}
                  item={item}
                  listingActions={listingActions}
                />
            ))}
          </List>
        </div>
      )}
    </Container>
  );
}
