import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import { DeleteForeverRounded, EditRounded } from "@mui/icons-material";
import { EditItemForm } from "./EditItemForm";

const useStyles = makeStyles({
  root: {
    maxWidth: 640,
    marginTop:12
  },
  largeimg: {
    height: null
  },
  media: {
    height: 120,
  },
});

export function ListingCard({ item, listingActions, sizes, categories, consignors }) {
  const classes = useStyles();
  const [isLargeImage, setLargeImage] = React.useState(false);
  const [isEditMode, setEditMode] = React.useState(false);
    
  return (
    <Card className={classes.root}>
        <CardMedia
          component="img"
          className={isLargeImage ? classes.largeimg : classes.media}
          image={item.imageUrl.split('image/upload/').join('image/upload/c_pad,b_lightgrey,w_800,h_1060/')}
          title={"Item_" + item._id} // w_600,h_800,c_fill
          onClick={(e) => {
            isLargeImage ? setLargeImage(false) : setLargeImage(true)
          }}
        />
        <CardContent>
          {isEditMode ?
            <EditItemForm 
              item={item}
              consignors={consignors}
              categories={categories}
              sizes={sizes}
              listingActions={listingActions}
              isEditMode={isEditMode}
              setEditMode={setEditMode}
            /> :
          <span>
            <Typography gutterBottom variant="h6" component="p">
              {item.title} | ${item.price}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {item.category}{item.size && ", Size:"+ item.size } 
            </Typography>
          </span> }
        </CardContent>
      <CardActionArea></CardActionArea>
      <CardActions>
      {!isEditMode &&
      <span>
      <Button
          variant="outlined"
          edge="end"
          size="medium"
          onClick={() => {
            //console.log('ListingCard.js edit', {item});
            setEditMode(true)
          }}
        > Edit
          <EditRounded />
        </Button>
        <Button
          variant="contained"
          edge="end"
          disabled={true}
          size="small"
          onClick={() => {
            console.log('delete?', {item});
            listingActions.deleteListing(item);
          }}
        > Delete
          <DeleteForeverRounded />
        </Button>
        </span>}
      </CardActions>
    </Card>
  );
}
