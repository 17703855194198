import * as Realm from "realm-web";
import { Button, Card, Divider, IconButton, InputAdornment, TextField } from "@mui/material";
import React from "react";
import { useErrorAlert } from "../hooks/useErrorAlert";
import { toggleBoolean } from "../utils";
import { handleAuthenticationError } from "../shared/handleAuthenticationError";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { EmailResetForm } from "./EmailResetForm";

export function EmailLoginForm({ realmApp, setShowLogin, setIsSignup, isSignup }) {
  const [showReset, setShowReset] = React.useState(false);

  const toggleIsSignup = () => {
    clearErrors();
    setIsSignup(toggleBoolean);
  };
  // Authentication errors
  const noErrors = {
    email: null,
    password: null,
    other: null,
  };
  const [error, setError] = React.useState(noErrors);
  const clearErrors = () => setError(noErrors);
  const NonAuthErrorAlert = useErrorAlert({
    error: error.other,
    clearError: () => {
      setError((prevError) => ({ ...prevError, other: null }));
    },
  });
  // Manage password visibility
  const [showPassword, setShowPassword] = React.useState(false);
  const toggleShowPassword = () => setShowPassword(toggleBoolean);

  const onFormSubmit = async ({ email, password }) => {
    const lowerEmail = email.toLowerCase();
    clearErrors();
    try {
      if (isSignup) {
        await realmApp.emailPasswordAuth.registerUser(lowerEmail, password);
      }
      await realmApp.logIn(Realm.Credentials.emailPassword(lowerEmail, password));
      // navigate(getLoginRedirectPath());
      setShowLogin(false);
    } catch (err) {
      handleAuthenticationError(err, setError);
    }
  };

  return (
    <span>
      {showReset ?
        <EmailResetForm realmApp={realmApp} /> :
        <Card className="auth-card" variant="outlined">
          <form
            className="auth-form"
            onSubmit={(e) => {
              e.preventDefault();
              const formData = new FormData(e.target);
              const { email, password } = Object.fromEntries(formData.entries());
              onFormSubmit({ email, password });
            }}
          >
            <NonAuthErrorAlert />
            <TextField
              id="input-email"
              name="email"
              label="Email Address"
              variant="outlined"
              error={Boolean(error.email)}
              helperText={error.email ?? ""}
            />
            <TextField
              id="input-password"
              type={showPassword ? "text" : "password"}
              name="password"
              label="Password"
              variant="outlined"
              error={Boolean(error.password)}
              helperText={error.password ?? ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleShowPassword}
                      onMouseDown={(e) => {
                        e.preventDefault();
                      }}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button type="submit" variant="contained" color="primary">
              {isSignup ? "Create Account" : "Log In"}
            </Button>
            <button
              type="button"
              className="link-button"
              onClick={() => toggleIsSignup()}
            >
              {isSignup
                ? "Already have an account? Log In"
                : "Sign up for an account"}
            </button>
          </form>
          <Divider style={{ margin: 24 }}></Divider>
          <button
            type="button"
            className="link-button"
            onClick={() => setShowReset(true)}>
            Reset Password
          </button>
        </Card>}
    </span>
  )
}