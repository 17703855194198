import React from "react";
import { useRealmApp } from "../components/RealmApp";
import {
  Container,
  TextField,
  Button,
  Card,
  Typography,
  ButtonGroup,
  CardHeader,
  CardContent,
} from "@mui/material";
import { ExitToApp } from "@mui/icons-material";
import { useUsers } from "../hooks/useUsers";
import { Link } from "react-router-dom";
import { StoresTable } from "../components/StoresTable";
import { ConsignorEditList } from "../components/ConsignorEditList";

export function ProfilePage() {
  const { logOut } = useRealmApp();
  const { upsertUser, user, loaded, currentUser } = useUsers();
  const [dirty, setDirty] = React.useState(false);
  const {fullname, venmo, isSeller, isOwner, isConsignor} = user || {};
  const roles = []

  if (isSeller) {
    roles.push('seller')
  }
  if (isConsignor) {
    roles.push('consignor')
  }

  const onFormSubmit = async ({ venmo, fullname }) => {
    try {
      console.log('submit', { venmo, fullname });
      upsertUser({ venmo, fullname });
    } catch (err) {
      console.error({err});
    }
  };

  const handleInput = async (e) => {
    setDirty(true);
  };

  return (
    <Container maxWidth="sm" disableGutters={true} className="main-container">
      <Card className="auth-card" variant="outlined">
        <ButtonGroup>
          {isSeller &&
          <Button
            component={Link}
            size="small"
            variant="contained"
            color="secondary"
            to={"/listings"}
            >
            &nbsp; Listings
          </Button>}
          {isOwner ? 
          <Button
            component={Link}
            size="small"
            variant="contained"
            color="secondary"
            to={"/shops"}
            >
            &nbsp; Settings
          </Button> : null}
          <Button
            size="small"
            variant="contained"
            color="secondary"
            href="/"
            onClick={async () => {
              try {
                await logOut();
              } catch (error) {
                console.error({error})
              }
            }}>
            <ExitToApp /> &nbsp; Log Out
          </Button>
        </ButtonGroup>
      </Card>
      <Card className="auth-card" variant="outlined">
        {loaded &&
        <form className="auth-form"
          onSubmit={(e) => {
            e.preventDefault();
            const formData = new FormData(e.target);
            const { venmo, fullname } = Object.fromEntries(formData.entries());
            onFormSubmit({ venmo, fullname });
            setDirty(false);
          }}
        >
          <Typography component="h4" variant="h5" gutterBottom>
            Profile {roles.length ? <span>({roles.join(', ')})</span>: ''} 
          </Typography>
          <TextField
            defaultValue={fullname}
            id="input-fullname"
            name="fullname"
            label="Display name"
            variant="outlined"
            onChange={handleInput}
          />
           <TextField
            defaultValue={currentUser?.profile?.email}
            id="input-email"
            name="email"
            label="Email"
            variant="outlined"
            disabled={true}
          />
          {(isSeller || isConsignor) &&
          <TextField
            id="input-venmo"
            defaultValue={venmo}
            name="venmo"
            label="Venmo Username"
            variant="outlined"
            onChange={handleInput}
          />}
          <Button disabled={!dirty} type="submit" variant="contained" color="primary">
            Update
          </Button>
        </form>}
      </Card>
      {isSeller ? 
        <Card style={{marginTop:9}}>
          <CardHeader style={{paddingBottom:0}} title="Seller settings" />
            <CardContent>
              <Typography variant="p">Consignors</Typography>
              <ConsignorEditList />
            </CardContent>
        </Card>
      : null}
      {user?.stores?.length ? 
          <Card style={{marginTop:9}}>
            <CardHeader style={{paddingBottom:0}} title="Admin settings" />
            <CardContent>
              <Typography variant="p">Shops</Typography>
              <StoresTable stores={user.stores} />
            </CardContent>
          </Card>
          : ""}
    </Container>
  );
}
