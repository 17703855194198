import React, { useEffect } from "react";
import * as Realm from "realm-web";
import { useRealmApp } from "../components/RealmApp";

function AuthRedirect() {
  const { currentUser } = useRealmApp();
    useEffect(() => {
        Realm.handleAuthRedirect();
      }, []);
    
      return (
        <div>
          {currentUser ? <p>You are logged in!</p> : <p>Login</p>}
        </div>
      );
}

export default AuthRedirect;