import {
    ImageList,
    ImageListItemBar,
    ImageListItem,
  } from "@mui/material";
import { Link } from "react-router-dom";

export function SingleImageList({items}) {
    return(
        <ImageList
        sx={{
          gridAutoFlow: "column",
          gridTemplateColumns: "repeat(auto-fill,minmax(190px,1fr)) !important",
          gridAutoColumns: "minmax(190px, 1fr)"
        }}>
        {items.map((item) => (
          <ImageListItem key={item._id}>
            <Link to={"/items/"+ item._id}>
              <img style={{width: '100%'}} src={item.imageUrl ? item.imageUrl.split('image/upload/').join('image/upload/c_pad,b_lightgrey,w_600,h_600/'):''} alt={item.title} />
              <ImageListItemBar title={item.title} />
            </Link>
          </ImageListItem>
        ))}
      </ImageList>
    )
}