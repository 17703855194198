import React from "react";
import axios from "axios";
import Config from "../shared/Config";
const { appId, endpointHost }  = Config();

const config = {
  // url: `https://${host}/app/${appId}/endpoint/getListing?itemId=${itemId}`,
  headers: { 
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
};

export function useItem(itemId) {
  config.url = `https://${endpointHost}/app/${appId}/endpoint/getListing?itemId=${itemId}`;
  const [item, setItem] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    setLoading(true)
    axios(config)
        .then(response => {
            console.log('useItem.js: fetch', itemId, response.data);
            setItem(response.data.documents[0]);
            setLoading(false);
            setLoaded(true);
        })
        .catch(err => {}); 
  }, [itemId]);
  
  return {
    loading,
    loaded,
    item
  };
}
