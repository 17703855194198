import { DeleteForeverRounded, Publish } from "@mui/icons-material";
import { Button, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import React from "react";
import { useConsignors } from "../hooks/useConsignors";

export function ConsignorEditList() {
    const [isEditMode, setEditMode] = React.useState(false);
    const [isFormDirty, setFormDirty] = React.useState(false);
    const { items: consignors, addConsignor, deleteConsignor } = useConsignors();

    const handleInputChange = (event) => {
        setFormDirty(true);
      };

    const handleSave = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const {name, venmo} = Object.fromEntries(formData.entries());
        console.log('addConsignor:',{name, venmo});
        await addConsignor({name, venmo});
        setEditMode(false);
      };

    return (
        <span> &nbsp;
        <Button variant="contained" size="small" color="primary"
          onClick={(e) => {
            setEditMode(true);
            setFormDirty(false);
        }}>[+] Add</Button>
        <Table sx={{ maxWidth: 380 }} size="small" aria-label="stores table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Venmo</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {consignors.map((c) => (
          <TableRow key={c._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">{c.name}</TableCell>
            <TableCell>{c.venmo || '--'}</TableCell>
            <TableCell>
            <Button
                size="small"
                onClick={() => {
                    console.log('delete?', {c});
                    deleteConsignor(c);
                }}>
              <DeleteForeverRounded />
             </Button>
            </TableCell>
          </TableRow>
        ))}
        </TableBody>
      </Table>
        {isEditMode &&
        <form className="consignor-edit-form" onSubmit={handleSave}>
            <TextField
                id="input-name"
                size="small"
                name="name"
                label="Name"
                onChange={handleInputChange}
                variant="outlined"
            />
            <TextField
                id="input-venmo"
                size="small"
                name="venmo"
                label="@venmo-name"
                onChange={handleInputChange}
                variant="outlined"
            />
            <Button disabled={!isFormDirty} 
              style={{marginRight:0}} type="submit" 
              variant="contained" color="primary">
              Save <Publish />
            </Button>
        </form>}
        </span>
    )
}