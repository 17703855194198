import React from "react";
import { useParams } from 'react-router-dom'
import {
  Card,
  CardContent,
  Typography,
  Container,
  ImageList,
  ImageListItem,
  LinearProgress,
  Button,
  CardHeader
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useItem } from "../hooks/useItem";
// import { BuyNowButton } from "../components/BuyNowButton";
import { useRealmApp } from "../components/RealmApp";
import { PayPalButtons } from "@paypal/react-paypal-js";

const useStyles = makeStyles({
  root: {maxWidth: 540, marginTop:12},
  media: {},
});

export function ItemCardDetail({setShowLogin}) {
  // const navigate = useNavigate();
  const { currentUser } = useRealmApp();
  const { itemId } = useParams();
  const { item, loaded } = useItem(itemId);
  const classes = useStyles();

  const imageUrls = [item.imageUrl];
  if (item.imageUrl2) {
    imageUrls.push(item.imageUrl2);
  }
  if (item.imageUrl3) {
    imageUrls.push(item.imageUrl3);
  }
  if (item.imageUrl4) {
    imageUrls.push(item.imageUrl4);
  }
  if (item.imageUrl5) {
    imageUrls.push(item.imageUrl5);
  }

  return (
    <Container maxWidth="sm" className="main-container">
    {loaded ? 
    <Card className={classes.root}>
      <CardHeader style={{ paddingTop:2, paddingBottom:2}} title={item.title} />
    <ImageList
        sx={{
          gridAutoFlow: "column",
          gridTemplateColumns: "repeat(auto-fill,minmax(85%,1fr)) !important",
          gridAutoColumns: "minmax(85%, 1fr)"
        }}>
        {imageUrls.map((item, idx) => (
          <ImageListItem key={idx}>
            <img style={{width: '100%'}} alt="product"
            src={item ? item.split('image/upload/').join('image/upload/c_pad,b_lightgrey,w_800,h_1060/'):''} />
          </ImageListItem>
        ))}
      </ImageList>
        <CardContent>
        {item.title} {"$"+ item.price}
          <Typography variant="body2" color="textSecondary" component="p">
            {item.category}{item.size && ", Size:" + item.size}
          </Typography>
        </CardContent>
        <CardContent>
        {currentUser ? 
        <span>
          <PayPalButtons fundingSource="venmo" forceReRender={[item.price]}/>
          <PayPalButtons fundingSource="paypal" forceReRender={[item.price]}/>
          <PayPalButtons fundingSource="card" forceReRender={[item.price]}/>
          {/* <BuyNowButton item={item} /> */}
        </span> : <Button variant="contained" color="primary" onClick={() => { 
                      sessionStorage.setItem('initPathname', window.location.pathname);
                      // navigate('/login');
                      setShowLogin(true);
                  }}>Login to Buy</Button>}
        </CardContent>
    </Card> : <LinearProgress />}
    </Container>
  );
}
